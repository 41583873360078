<template>
  <section aria-label="Homepage" class="home">
    <h1>Hello! <span class="wave">👋</span></h1>
    <h2>I'm Sahil Kokamkar</h2>
    <p>Currently pursuing bachelor's in Computer Science</p>
    <h3>I like DevOps Stuff</h3>
    <h3>And trying out new things</h3>
    <p>I write blog on <a href="//blog.sahil.world" target="_blank" rel="noopener">blog.sahil.world</a></p>
  </section>
</template>

<style scoped>
.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: 1;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
